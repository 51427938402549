.to-print {
    display: none;
    font-size: large;
    width: 80vw;
    height: 150vh;
}

.dashboard-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1%;
    padding-bottom: 10vh;
    /* background-color: red; */
    height: 100vh;

}

.dashboard-user-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    height: 10vh;
    width: 80vw;
    /* background-color: red; */
    
}


.dashboard-info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 10vw;
    font-size: 12px;
    height: 15vh;
    overflow-x: hidden;
    /* text-overflow: clip; */
    /* background-color: red; */
}

.big {
    /* background-color: red;/ */
    width: 20vw;
}

.name {
    font-weight: 500;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.line {
    /* position: absolute; */
    width: 200vw;
    height: 2x;
   
}

.dashboard-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    align-content: center;
    /* background-color: yellow; */
    margin-bottom: 2vh;
    border-bottom: 0.2px black solid;
}

.dashboard-button {
    background-color: white;
    border: none;
}