body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.alinhamento{
  display:flex;
  flex-direction: column;
  justify-content:center; 
  align-items: center;

}
.tabelaCursos{
  float:center;
  width:80%;
  margin:30px;
  text-align:center;
  background-color:white;
  font-family:poppins; 
  color:black;
  border-radius:5px;
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.tabelaCursos thead tr {
  background-color: rgb(14, 13, 12);
  color: rgb(255, 223, 41);
}
